.image,
.status {
  transition: all 0.3s ease-in-out;
}
.imageHover {
  scale: 1.05;
}

.statusHover {
  opacity: 0;
  transform: translateX(-50px);
}
